import React from 'react';
import './App.css';
import {OfferTransfer} from "./pages/OfferTransfer";
import {FcmTokenProvider} from "./closures/getUserFCMToken";
import { Experimental_CssVarsProvider as EXPERIMENTAL_CSS_VARS_PROVIDER } from '@mui/material/styles';


function App() {
  return (
      <EXPERIMENTAL_CSS_VARS_PROVIDER>
          <FcmTokenProvider>
            <OfferTransfer/>
          </FcmTokenProvider>
      </EXPERIMENTAL_CSS_VARS_PROVIDER>
  );
}

export default App;
